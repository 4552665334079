:root {
  --primary: #6e75a8;
  --orangelight: #aaaaaa;
  --buttonlight: #aaaaaa;
  --lightblack: #1b1b1b;
  --white: #ffffff;
  --black: #000;
  --lightgray: #2d2d2d;
  --lightwhite: #121212;
  --register: #131313;
  --background-image: url("./assets/images/loginbg.jpg");
  --background-register: url("./assets/images/imageg.png");
  --blackbtn: #111;
  --textcolor: #fff;
  --blurback: rgba(0, 0, 0, 0.356);
  --icon_white: 0;
}
.light-mode {
  --primary: #6e75a8;
  --orangelight: #998b76;
  --lightblack: #ecebeb;
  --white: #000;
  --darkbg: #000;
  --black: #ffffff;
  --buttonlight: #cabca5;
  --lightgray: #e5e5e5;
  --lightwhite: #f8f8f8;
  --register: #ececec;
  --background-image: url("./assets/images/whitegirl.jpg");
  --background-register: url("./assets/images/imagewhiteg.png");
  --pieach: #8a7552 !important;
  --blackbtn: #999;
  --blurback: rgba(0, 0, 0, 0.356);
  --textcolor: #000;
  --icon_white: 0;
}
.dark-mode {
  --primary: #6e75a8;
  --orangelight: #aaaaaa;
  --buttonlight: #aaaaaa;
  --lightblack: #1b1b1b;
  --white: #ffffff;
  --black: #000;
  --lightgray: #2d2d2d;
  --lightwhite: #121212;
  --register: #131313;
  --background-image: url("./assets/images/girl.png");
  --background-register: url("./assets/images/imageg.png");
  --blurback: rgba(0, 0, 0, 0.356);
  --pieach: #cbbca7 !important;
  --icon_white: 1;
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.header.sticky {
  position: sticky;
  /* Make it sticky */
  top: 0;
  /* Stick to the top */
  z-index: 9998;
  /* Ensures the header stays above other elements */
}

.userpic.userpic_main {
  padding: 10px !important;
}
.userpic.userpic_main a {
  color: var(--white);
}
.userpic.userpic_main img {
  object-fit: contain !important;
  padding: 3px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(var(--icon_white));
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(var(--icon_white));
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
  word-break: break-all;
} */

input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
}

a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}

button:focus {
  outline: none;
  border: none;
}

button:focus,
input:focus {
  box-shadow: none !important;
}

button {
  cursor: pointer;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ls li {
  list-style: none;
}

.cur {
  cursor: pointer;
}

.fulw img {
  width: 100%;
}

.rel {
  position: relative;
}

.ofit img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  color: white;
}

body {
  /* font-family: "Open Sans", sans-serif !important; */
  font-family: "Montserrat", serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--lightwhite);
  color: var(--white);
}
.login_header {
  background-color: transparent !important;
  box-shadow: none !important;
}

header {
  background-color: var(--lightblack);
  padding: 15px 0;
  position: relative;
  z-index: 998;
  box-shadow: 0px 2px 10px 0px #0000005c;
}

.toplogo {
  width: 200px;
}

.topbtn {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid transparent;
  width: 200px;
  padding: 6px 15px;
  text-transform: uppercase;
  background: transparent;
}

.loginbtn {
  border-color: var(--orangelight);
}

.loginbtn.active {
  border-color: var(--primary);
}
.registerbtn.active {
  border-color: var(--primary);
}

.loginbtn:hover {
  background: var(--primary);
  color: var(--black);
}

.registerbtn {
  border-color: var(--orangelight);
}

.registerbtn:hover {
  background: var(--orangelight);
  color: var(--black);
}

.weltextbox h1 {
  font-size: 16px;
  color: var(--orangelight);
  line-height: 20px;
  text-transform: uppercase;
}

.weltextbox h2 {
  font-size: 40px;
  color: var(--white);
  font-weight: 600;
  line-height: 46px;
}

.weltextbox p {
  font-size: 16px;
  color: var(--white);
  line-height: 24px;
  text-align: justify;
}

.findbtn {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  max-width: 400px;
  width: 100%;
  padding: 14px 0;
  text-transform: uppercase;
  background: transparent;
  display: inline-block;
}

.view_btn {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  width: fit-content;
  width: 100%;
  padding: 10px 0;
  text-transform: uppercase;
  background: transparent;
  display: inline-block;
}

.findbtn:hover {
  background: var(--primary);
  color: var(--black);
}

.weliconblock .iconwel {
  width: 60px;
}

.weliconblock .iconwel img {
  width: 100%;
}

.weliconblock h5 {
  font-size: 20px;
  color: var(--primary);
  font-weight: 600;
  text-transform: uppercase;
}

.weliconblock p {
  color: var(--white);
  font-size: 16px;
  text-wrap: balance;
}

@media (max-width: 600px) {
  .weliconblock p {
    display: none;
  }
}

.ftlogoblock h2 {
  color: var(--orangelight);
  font-weight: 600;
  font-size: 26px;
}

.ftlogoblock h6 {
  font-size: 15px;
  color: var(--white);
  line-height: 24px;
  text-wrap: balance;
}
.ftlogoblock .needsign {
  text-wrap: balance;
}
.ftlogoblock span {
  font-size: 15px;
  color: var(--orangelight);
  line-height: 24px;
}

.ftlogoblock h4 {
  font-size: 18px;
  color: var(--white);
  line-height: 24px;
  font-weight: 500;
}

.copyrighttext {
  font-size: 16px;
  color: var(--orangelight);
  text-transform: capitalize;
}

.ftblock {
  border-left: 2px solid var(--white);
  /* background: var(--lightblack); */
}

@media (max-width: 767px) {
  .ftblock {
    border-left: none;
  }
}

.ftblock h3 {
  font-size: 18px;
  color: var(--orangelight);
  font-weight: 600;
}

.ftlink a {
  color: var(--white);
  font-size: 16px;
  text-transform: capitalize;
}

.ftlink a:hover {
  color: var(--primary);
}
.login_registermain {
  background: var(--register) var(--background-register) no-repeat bottom left;
  background-size: 50%;
  background-attachment: fixed;
}
.regblock {
  padding: 10% 0;
  min-height: 100vh;
}

@media (max-width: 786px) {
  .regblock {
    background-size: 100%;
  }
}

.regblock::after {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--register) var(--background-register) no-repeat bottom left;
  background-size: 50%;
  z-index: -1;
}

.regblock {
  padding: 10% 0;
  position: relative;
  z-index: 1;
}

.regform {
  background: var(--lightgray);
  padding: 30px;
  border-radius: 10px;
}

.regtitle h2 {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
}

.regtitle p {
  font-size: 16px;
  color: var(--white);
}

.regform label {
  font-size: 16px;
  color: var(--white);
}

.regform input[type="email"],
.regform input[type="text"],
.regform input[type="number"],
.regform input[type="password"],
select {
  font-size: 16px;
  color: var(--white);
  width: 100%;
  background: var(--lightwhite) !important;
  border-radius: 8px;
  padding: 10px;
  border: none;
}

.regbtnsubmit {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid var(--primary);
  width: 100%;
  padding: 14px 0;
  text-transform: uppercase;
  background: transparent;
  display: inline-block;
}

.regbtnsubmit:hover {
  background: var(--primary);
  color: var(--black);
}

.regform .listbox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.regform .listbox label {
  font-size: 16px;
  color: var(--white);
}

/***check box css****/
.useruploadpic .listbox label {
  font-size: 16px;
  color: var(--white);
}

.listbox .cbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.listbox .cbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.listbox .cbox a {
  color: #fff;
}

/* Create a custom checkbox */
.listbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: var(--orangelight);
}

/* On mouse-over, add a grey background color */
.listbox .cbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.listbox .cbox input:checked ~ .checkmark {
  background-color: #3c3c3c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.listbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.listbox .cbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.listbox .cbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.outerupload {
  border: 2px solid var(--orangelight);
  border-radius: 10px;
  position: relative;
  width: 200px;
  height: 100px;
  text-align: center;
}

.uploadimg {
  height: 100%;
  line-height: 100px;
}

.uploadimg svg {
  fill: var(--orangelight);
  font-size: 40px;
}

.outerupload input[type="file"] {
  position: absolute;
  z-index: 2;
  inset: 0;
  cursor: pointer;
  opacity: 0;
}

.topsearch {
  position: relative;
}

.topsearch .similar_search {
  padding: 0;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--lightblack);
  border-radius: 24px;
  padding-top: 45px;
  top: 0;
  z-index: 98;
}

.topsearch .similar_search li a {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ffffff54;
}

.topsearch .similar_search li a:last-child {
  border-bottom: none;
}

.topsearch .similar_search li a span {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
}

.topsearch .similar_search li .search_icon {
  background-color: var(--lightwhite);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  padding: 5px;
}
.topsearch .similar_search li .search_icon img {
  filter: invert(1);
}
.light-mode .topsearch .similar_search li .search_icon img {
  filter: invert(0);
}
.topsearch form input {
  font-size: 16px;
  color: var(--white);
  width: 100%;
  background: var(--lightwhite);
  border-radius: 100px;
  padding: 10px 30px;
  border: none;
  position: relative;
  z-index: 989;
}

.topmenu {
  background: var(--lightwhite);
  width: 60px;
  height: 60px;
  /* aspect-ratio: 1; */
  border-radius: 100px;
  padding: 12px;
  line-height: 10px;
  border: 2px solid transparent;
  position: relative;
}
@media (max-width: 768px) {
  .topmenu {
    background: var(--lightwhite);
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

.topmenu img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topmenu.active {
  border: 2px solid var(--orangelight);
}

.topmenu:hover,
.topmenu:focus,
.topmenu:focus-visible {
  border: 2px solid var(--orangelight);
}

.menubox {
  background: var(--lightblack);
  min-width: 429px;
  position: absolute;
  right: 0;
  z-index: 600;
  border-radius: 0px 0px 15px 15px;
  top: 70px;
}

@media (max-width: 768px) {
  .menubox {
    top: 55px;
    right: 50%;
    min-width: 90%;
    transform: translateX(50%);
  }
}

/* @media (max-width: 767px) {
  .menubox {
    right: 0%;
    transform: translateX(0%);
  }
} */

.menubox > div {
  padding: 20px;
}

.menubox h3 {
  color: var(--white);
  font-size: 28px;
  font-weight: 600;
  border-bottom: 1px solid #3c3c3c;
  padding-bottom: 6px;
}

.menubox a:hover h4,
.menubox a:hover p,
.menubox a:hover .iconmenu,
.menubox button:hover h4,
.menubox button:hover p,
.menubox button:hover .iconmenu {
  opacity: 0.8;
}

.menubox .iconmenu {
  width: 60px;
  height: 60px;
}

.menubox .iconmenu.fulw {
  width: 60px;
  height: 60px;
  padding: 14px;
  background: var(--lightwhite);
  border-radius: 50px;
}

.morebtn a,
.morebtn button {
  text-transform: uppercase;
  font-size: 18px;
  color: var(--white);
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 8px;
  padding: 7px;
  display: block;
  width: 100%;
  background: transparent;
}

@media (max-width: 786px) {
  .morebtn a,
  .morebtn button {
    font-size: 16px;
  }
}

.morebtn a:hover,
.morebtn button:hover {
  background: var(--primary);
  color: var(--white);
}

.timeago {
  color: var(--orangelight) !important;
}

.minimsg {
  font-size: 16px;
  color: var(--orangelight);
  width: 200px;
  /* Set a fixed width */
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowing content */
  text-overflow: ellipsis;
  /* Shows "..." when the text overflows */
}

.inboxser input {
  font-size: 16px;
  color: var(--white);
  width: 100%;
  background: var(--lightgray);
  border-radius: 100px;
  padding: 10px 30px;
  border: none;
}

.helpset {
  border-top: 1px solid #3c3c3c;
}

.helpset a {
  align-items: center;
}

.edittitle h3 {
  color: var(--white);
  font-size: 26px;
  font-weight: 600;
}

.savebtn {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 8px;
  padding: 6px 30px;
  display: block;
  background: transparent;
}

@media (max-width: 767px) {
  .savebtn {
    font-size: 12px;
  }
}

.savebtn:focus {
  border: 2px solid var(--primary);
}

.savebtn:hover {
  background: var(--primary);
  color: var(--white);
}

.formlook label {
  font-size: 16px;
  color: var(--white);
  text-transform: capitalize;
}

.formlook textarea,
.formlook input[type="text"],
.formlook input[type="number"],
.formlook select {
  background: var(--lightwhite);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  border: none;
}

.accordion .accordion__button {
  background: var(--lightwhite) !important;
  position: relative;
}

.accordion .accordion__button::before {
  right: 0;
  left: auto;
  position: absolute;
  top: 50%;
  color: var(--white);
  transform: rotate(45deg);
}

.accordion .accordion__button[aria-expanded="true"]::before {
  transform: rotate(-135deg);
}

.accordion .accordion__item {
  border-bottom: 1px solid var(--white);
}

.formlook .accordion .accordion__item .acciconimg {
  width: 40px;
}

.formlook .accordion .accordion__item h4 {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}

.formlook .listbox label {
  font-size: 16px;
}

.uploadfile input[type="file"] {
  position: absolute;
  background: red;
  inset: 0;
  z-index: 33;
  opacity: 0;
  cursor: pointer;
}

.upload_image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.upload_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inload {
  border: 2px solid var(--orangelight);
  border-radius: 10px;
  display: grid;
  place-items: center;
  text-align: center;
}
.upimage {
  width: 40px;
  height: 40px;
}
.upimage .inimag {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  overflow: hidden;
}
.upimage .inimag video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.upimage button {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 11px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 44;
}
.inload svg {
  fill: var(--orangelight);
  stroke: var(--orangelight);
  font-size: 50px;
}

.inload h3 {
  color: var(--white);
  font-size: 20px;
  margin-top: 10px;
}

.bgload .inload {
  height: 400px;
}

.smallload .inload {
  height: 200px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Applies the blur effect */
  z-index: 997; /* Ensures the element is above others */

  overflow-y: auto;
  height: 100vh;
  z-index: 989898;
}

.popup-content {
  position: relative;
  padding: 15px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .popup-content {
    padding: 40px 15px;
  }
}
.close_mumber {
  top: -50px;
  right: -0px;
}
.popup-overlay .close-button {
  background: none;
  border: none;
  color: #fff;
  color: var(--white);
  cursor: pointer;
  font-size: 35px;
  position: absolute;
  right: 27px;
  top: 25px;
  z-index: 9898;
  line-height: normal;
  background: #7b643f;
  height: fit-content;
  height: 30px !important;
  width: 30px !important;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
@media (max-width: 786px) {
  .popup-overlay .close-button {
    position: fixed;
  }
}
.popup-overlay .close-button svg {
  font-size: 22px;
  color: red;
}

.planbox {
  background: var(--lightgray);
  border-radius: 20px;
  padding: 80px 20px;
  color: var(--white);
  width: 100%;
  height: 100%;
}

.planbox h1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.planbox h2 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
}

.planbox p {
  font-size: 16px;
  font-weight: 400;
}

.planbox h3 {
  font-size: 20px;
  font-weight: 400;
}

.planbox h4 {
  font-size: 16px;
  font-weight: 400;
}

.planbox button {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 8px;
  padding: 6px;
  display: block;
  width: 100%;
  background: transparent;
}

.planbox button:hover {
  background: var(--primary);
  color: var(--white);
}

.planbox svg {
  fill: var(--orangelight);
}

.gapy {
  padding: 50px 0;
}

.scrollyover {
  height: 300px;
  overflow-y: auto;
  padding-right: 8px;
}

.member-tabs {
  display: flex;
  gap: 25px;
}

.member-tabs .react-tabs__tab-list {
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--lightgray) !important;
}

.member-tabs ul li h3 {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #3c3c3c;
  padding-bottom: 6px;
}

.member-tabs .iconmenu {
  width: 40px;
  aspect-ratio: 1;
  background: #1b1b1b;
  border-radius: 100px;
  overflow: hidden;
  padding: 4px;
}

.member-tabs ul li h4 {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
  text-transform: capitalize;
}

.member-tabs > ul > li {
  border: none !important;
}

.member-tabs .react-tabs__tab--selected,
.member-tabs .react-tabs__tab:hover {
  background: var(--lightgray) !important;
  border: none !important;
  border-radius: 10px !important;
}

.member-tabs .react-tabs__tab--selected::after {
  display: none !important;
}

.member-tabs .react-tabs__tab-panel {
  flex-grow: 1;
}

.mship h1 {
  font-size: 28px;
  font-weight: 400;
  color: var(--white);
  text-transform: capitalize;
}

.meuser {
  background: var(--lightgray);
  border-radius: 10px;
  padding: 25px;
}

.usermship {
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}

.yourmship h5 {
  font-size: 20px;
  color: var(--white);
}

.yourmship h6 {
  font-size: 16px;
  color: var(--white);
}

.shipbtns .btn {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid transparent;
  min-width: 200px;
  width: 100%;
  padding: 10px 0;
  text-transform: uppercase;
  background: transparent;
  display: inline-block;
}

.shipbtns .pribtn {
  border: 2px solid var(--primary);
}

.shipbtns .pribtn:hover {
  background: var(--primary);
  color: var(--black);
}

.shipbtns .celbtn {
  border: 2px solid #a91d4a;
}

.shipbtns .celbtn:hover {
  background: #a91d4a;
  color: var(--black);
}

.blockv {
  background: var(--lightgray);
  border-radius: 10px;
  padding: 20px;
}

.blockv h3 {
  color: var(--white);
  font-size: 20px;
}

.blockv h4 {
  color: var(--orangelight);
  font-size: 18px;
}

.blockv .listbox label {
  font-size: 16px;
  color: var(--white);
}

.blockv p {
  font-size: 16px;
  color: var(--white);
  text-align: justify;
}

.clicklink {
  text-decoration: underline;
  color: var(--orangelight);
  font-size: 16px;
  font-weight: 600;
}

.faqblock h4 {
  color: var(--white);
  font-size: 20px;
}

.faqblock p {
  color: var(--white);
  font-size: 16px;
}

.dashbox {
  padding: 30px 0;
}

.sidefixedbox {
  top: 106px;
  z-index: 99;
  height: 83vh;
  overflow: auto;
  position: sticky;
}

@media (max-width: 990px) {
  .sidefixedbox {
    top: 74px;
    z-index: 33;
    padding: 25px 25px;
  }
}

@media (max-width: 786px) {
  .sidefixedbox {
    top: 162px;
    padding: 26px 8px;
    position: fixed;
    width: 100%;
  }
}

.listoficons:hover img,
.listoficons:hover h4,
.listoficons :hover {
  opacity: 0.8;
}

.listoficons .userpic {
  width: 60px;
  aspect-ratio: 1;
  background: var(--lightblack);
  /* padding: 10px; */
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listoficons svg {
  color: var(--orangelight);
  padding: 3px;
  font-weight: 400;
}
.listoficons .main_homei {
  color: var(--white);
}
.listoficons h4 {
  font-size: 16px;
  color: var(--white);
  text-transform: capitalize;
}

.grouptitle {
  font-size: 20px;
  color: #a5a7a7;
  font-weight: 400;
}

.postblock h4 {
  color: var(--white);
  font-size: 16px;
}

.postblock h5 {
  color: var(--white);
  font-size: 14px;
}

.postblock a {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}

.listnumber h4 {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
}

.menubox h4 {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
}

.menubox p {
  font-size: 14px;
  color: var(--white);
}

@media (min-width: 1450px) {
  .listoficons h4 {
    font-size: 18px;
    color: var(--white);
    text-transform: capitalize;
  }

  .grouptitle {
    font-size: 22px;
    color: #a5a7a7;
  }

  .postblock h4 {
    color: var(--white);
    font-size: 18px;
  }

  .postblock h5 {
    color: var(--white);
    font-size: 16px;
  }

  .listnumber h4 {
    font-size: 18px;
    color: var(--white);
  }

  .menubox h4 {
    font-size: 18px;
    color: var(--white);
    font-weight: 500;
  }

  .menubox p {
    font-size: 16px;
    color: var(--white);
  }
}

.groupmy .listoficons h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
}
.listoficons p {
  font-size: 14px;
  color: var(--white);
}

.linehr {
  border-top: 1px solid var(--white);
}

.listoficons h6 {
  color: var(--white);
  font-size: 14px;
  padding-top: 3px;
}

.visitscroll {
  max-height: 340px;
  overflow-y: auto;
}

.viewmore a {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}

.viewmore a:hover {
  color: var(--orangelight);
}

.postblock {
  background: var(--lightgray);
  border-radius: 30px;
  overflow: hidden;
  padding: 4px;
}

.urpic {
  width: 60px;
  aspect-ratio: 1;
}

.btnoption {
  background: transparent;
  border: none;
  color: var(--white);
}

.imgblock {
  aspect-ratio: 16/9;
}

.leftscrollbox {
  height: 290px;
  overflow-y: auto;
}

.searchleftfilter h2 {
  font-size: 30px;
  color: var(--white);
  text-transform: capitalize;
}

.searchleftfilter h1 {
  font-size: 30px;
  color: var(--white);
  text-transform: capitalize;
}

.filtersearch input {
  background: var(--lightwhite);
  color: var(--white);
  font-size: 16px;
  border-radius: 100px;
  padding: 10px 15px;
  width: 100%;
  border: none;
}

.filterlist {
  background: var(--lightwhite);
  border: none;
  border-radius: 10px;
  padding: 4px;
}

.filterlist:hover,
.filterlist.active {
  background: var(--buttonlight);
}

.filterlist .eventicon {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  padding: 10px;
  background: var(--lightblack);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterlist h3 {
  color: var(--white);
  font-size: 16px;
  text-align: left;
}

.newebentbtn button {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  text-align: center;
  border: none;
  border-radius: 8px;
  padding: 6px;
  display: block;
  width: 100%;
  background: var(--primary);
}

.newebentbtn a:hover,
.newebentbtn button:hover {
  background: var(--primary);
  color: var(--white);
}

.categorieslist h6 {
  color: #a5a7a7;
  font-size: 18px;
}

.categorieslist .listbox label {
  font-size: 16px;
  color: var(--white);
}

.eventname {
  background: var(--lightgray);
  border-radius: 10px;
  padding: 15px;
}

.eventname .eventpic {
  min-width: 180px;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 6px;
  overflow: hidden;
}

.eventname h3 {
  color: var(--white);
  font-size: 16px;
}

.eventname h4 {
  color: var(--white);
  font-size: 16px;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.eventname h5 {
  color: var(--white);
  font-size: 16px;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.eventname h6 {
  color: var(--white);
  font-size: 16px;
  margin-top: auto;
}

.inbtns .btn {
  text-transform: uppercase;
  font-size: 15px;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 8px 16px;
  text-align: center;
}

.inbtns .sebtn {
  border: 2px solid var(--primary);
}

.inbtns .prbtn {
  border: 2px solid var(--orangelight);
}

.inbtns .sebtn:hover {
  border: 2px solid var(--primary);
  background: var(--primary);
}

.inbtns .prbtn:hover {
  border: 2px solid var(--orangelight);
  background: var(--orangelight);
}

.evleft {
  top: 146px;
  z-index: 43;
}

.evname {
  background: var(--lightgray);
  border-radius: 10px;
  padding: 25px;
}

.eventtitle {
  border-bottom: 2px solid var(--lightgray);
}

.eventtitle h1 {
  color: var(--white);
  font-size: 24px;
}

.evname {
  background: var(--lightgray);
  border-radius: 10px;
  padding: 20px;
}

.evname .evephoto {
  width: 50px;
  aspect-ratio: 1;
}

.whatthink {
  background: var(--orangelight);
  border-radius: 8px;
  padding: 8px 10px;
}

.whatthink h4 {
  color: var(--white);
  font-size: 16px;
}

.addphotothink h4 {
  font-size: 16px;
  color: var(--white);
}

.iconphoto {
  width: 30px;
}

.evettimeheaing h4 {
  font-size: 16px;
  color: var(--white);
}

.evettimeheaing h5 {
  color: var(--white);
  font-size: 16px;
}
.evettimeheaing a {
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
}

.contpost p {
  color: var(--white);
  font-size: 16px;
}

.postchatheart .btn {
  width: 25px;
  padding: 0;
}

.postchatheart svg {
  color: var(--white);
  font-size: 20px;
}

.postchatheart .liked svg {
  color: red;
}

.postchatheart p {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
}

.postimgfull {
  aspect-ratio: 16/9;
}

.posttopbtns .btn {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 8px 25px;
  text-align: center;
  min-width: 100px;
}
.posttopbtns .redbtn {
  border: 2px solid #c51b54 !important;
}

.posttopbtns .sebtn {
  border: 2px solid var(--primary);
}

.posttopbtns .prbtn {
  border: 2px solid var(--orangelight);
}

.posttopbtns .editbtn {
  border: 2px solid #6e75a8;
}
.posttopbtns .editbtn:hover {
  border: 2px solid #6e75a8;
  background: #6e75a8;
}

.posttopbtns .sebtn:hover {
  border: 2px solid var(--primary);
  background: var(--primary);
  color: var(--black);
}

.posttopbtns .prbtn:hover {
  border: 2px solid var(--orangelight);
  background: var(--orangelight);
  color: var(--black);
}

.posttopbtns .redbtn:hover {
  border: 2px solid #c51b54;
  background: #c51b54;
}

.deticontext .pubimg {
  width: 40px;
}

.deticontext h5 {
  color: var(--white);
  font-size: 16px;
}

.detailbox {
  background: var(--lightgray);
  padding: 20px;
  border-radius: 20px;
}

.detailbox h3 {
  color: var(--pieach);
  font-size: 20px;
}

.detailbox p {
  color: var(--white);
  font-size: 16px;
}

.vmore a {
  color: var(--white);
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}

.memperson > div,
.memperson {
  aspect-ratio: 9/11;
}

.cneventup {
  background: var(--black);
  border-radius: 20px;
  padding: 20px;
}

.cneventup h3 {
  color: var(--white);
  font-size: 20px;
  text-transform: capitalize;
}

.cneventup input[type="text"],
.cneventup input[type="time"],
.cneventup input[type="date"],
.cneventup input[type=""],
.cneventup textarea {
  background: var(--lightwhite) !important;
  color: var(--white);
  font-size: 16px;
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 10px 15px;
}

.cneventup .listbox .cbox {
  color: var(--white);
  font-size: 16px;
}

.cneventup button {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  text-align: center;
  border: none;
  border-radius: 8px;
  padding: 6px;
  display: block;
  width: 100%;
  background: var(--primary);
}

.cneventup a:hover,
.cneventup button:hover {
  background: var(--primary);
  color: var(--white);
}

.datetimeline .stextlabel {
  position: absolute;
  top: 0;
  color: var(--white);
  font-size: 12px;
  left: 10px;
  top: 4px;
  z-index: 3;
}

.cneventup .datetimeline input {
  padding-top: 20px;
}

.datetimeline .stextlabel {
  position: absolute;
  top: 0;
  color: var(--white);
  font-size: 12px;
  left: 10px;
  top: 4px;
  z-index: 3;
}

.grouplistleft {
  max-height: 300px;
  overflow-y: auto;
}

.sortbtn {
  color: #7b7c7c;
  font-size: 16px;
  border: none;
  background: transparent;
}

.groupblck {
  background: var(--lightgray);
  border-radius: 10px;
  overflow: hidden;
}

.groupblck h2 {
  color: var(--white);
  font-size: 19px;
}

.groupblck h3 {
  color: var(--white);
  font-size: 16px;
}

.groupblck h4 {
  color: var(--white);
  margin-top: auto;
  font-size: 16px;
}

.groupblck .grpic {
  aspect-ratio: 9/11;
  width: 100px;
}

.postwrite p {
  color: var(--white);
  font-size: 16px;
}

.postlikeoptions .linklikebtn {
  border: none;
  background: transparent;
  font-size: 20px;
  color: var(--secretary);
}

.postlikeoptions .linklikebtn span {
  font-size: 16px;
}

.formen {
  background: var(--lightwhite);
  overflow: hidden;
  border-radius: 10px;
}

.formen .fromimg {
  min-width: 140px;
  width: 100%;
  height: 100%;
}

.recchat_main {
  background-color: var(--lightgray) !important;
}

.formen .recChat {
  width: 93px;
  height: 118px;
}
.formen h3 {
  color: var(--white);
  font-size: 20px;
}

.formen h4 {
  color: var(--white);
  font-size: 16px;
}

.formen p {
  color: var(--white);
  font-size: 16px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.memform .iconforum {
  width: 30px;
}

.memform h6 {
  font-size: 16px;
  color: var(--white);
}

.addcommentbox input {
  background: var(--lightblack);
  color: var(--white);
  font-size: 16px;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  width: 100%;
}

.commpost {
  background: var(--lightwhite);
}

.commpost .postpic {
  width: 35px;
  aspect-ratio: 1;
}

.commpost h3 {
  font-size: 17px;
  color: #a5a7a7;
}

.commpost h6 {
  font-size: 16px;
  color: var(--white);
}

.commpost p {
  font-size: 16px;
  color: var(--white);
}

.inboxblock .showonly label {
  color: var(--white);
  font-size: 16px;
}

/****20 dec***/

.ggboxbtn a {
  color: var(--orangelight);
  font-size: 16px;
}

.ggboxbtn a:hover {
  color: var(--primary);
}

.ggimagebox {
  aspect-ratio: 1;
  cursor: pointer;
  height: 300px;
}
.ggimagebox > div {
  height: 100%;
  width: 100%;
}

.modalgg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99899;
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: black;
}

.modalgg .modal-content {
  position: relative;
  /* background-color: #fefefe; */
  height: 100%;
  margin: auto;
  padding: 0;
  width: 100%;
  /* max-width: 1200px; */
  text-align: center;
}

.modalgg .close {
  color: white;
  position: absolute;
  top: 10px;
  right: 100px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
  z-index: 666;
  cursor: pointer;
}

@media (max-width: 1135px) {
  .modalgg .close {
    right: 30px;
  }
}

.modalgg .close:hover {
  color: var(--orangelight);
}

.modalgg .mySlides {
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modalgg .mySlides img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1135px) {
  .modalgg .mySlides img {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
}

.modalgg .prev,
.modalgg .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
}

.modalgg .next {
  right: 0;
}

.modalgg .prev {
  left: 0;
}

.modalgg .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.modalgg .caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.modalgg img.demo {
  opacity: 0.6;
  cursor: pointer;
}

.modalgg .active,
.modalgg .demo:hover {
  opacity: 1;
}

.modalgg img.hover-shadow {
  transition: 0.3s;
}

.modalgg .hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.urnamgg {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.ggboxes .react-tabs__tab-list {
  display: flex;
  /* Spread the tabs evenly */
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
  /* Border at the bottom */
}

/* Styling for each individual Tab */
.ggboxes .react-tabs__tab {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  background-color: #111;
  /* Default background color */
  border: none;
  /* Light border around each tab */
  border-radius: 5px;
  /* Rounded corners */
  transition: background-color 0.3s, color 0.3s;
  /* Smooth transitions */
  margin-right: 10px;
  /* Space between tabs */
}

.ggboxes .react-tabs__tab:focus::after {
  background: none !important;
}

/* Active Tab (Selected Tab) styling */
.ggboxes .react-tabs__tab--selected {
  border: none;
  background-color: var(--orangelight);
  /* Blue background for selected tab */
  color: black;
  /* White text for the selected tab */
  font-weight: bold;
  /* Bold font for the active tab */
  border-color: var(--orangelight);
  /* Border matching the selected tab's background */
}

/* Hover effect for tabs */
.ggboxes .react-tabs__tab:hover {
  background-color: var(--orangelight);
  /* Slightly darker gray when hovered */
}

/* Optional: Styling the Tab Panels */
.ggboxes .react-tabs__tab-panel {
  padding: 0px;
  border: none;
  background-color: none;
  border-radius: 0px;
}

.image-container {
  max-width: 100%;
  /* Ensures the container doesn't exceed the parent's width */
  max-height: 100%;
  /* Limits the height of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Prevents overflow if the image is too large */
}

.image-container img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

/* Container for the entire switch component */
.switchbtndiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Styling for the label containing the text */
.switchbtnlableh4 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

/* The switch container */
.switchbtnlable {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 50px;
}
/* Hide the default checkbox */
.switchbtnlable input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider - the background of the switch */
.switchbtn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* The circle inside the switch */
.switchbtn:before {
  position: absolute;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  bottom: 4px;
  content: "";
  height: 17px;
  left: 4px;
  width: 17px;
}

/* When the checkbox is checked, change the background and move the slider */
.switchbtnlable input:checked + .switchbtn {
  background-color: #7b643f;
  /* Green color for "On" */
}

.switchbtnlable input:checked + .switchbtn:before {
  transform: translateX(26px);
  /* Move the circle to the right when checked */
}

/* Add an animation effect when toggling */
.switchbtnlable input:checked + .switchbtn:before {
  animation: slideIn 0.3s ease-in-out;
}

.warning_text p span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.warning_text {
  border-left: 3px solid yellow;
  padding-left: 10px;
}

.d-block.recive_two {
  height: 226px;
  border-radius: 20px;
  overflow: hidden;
}

/* Keyframe animation for the switch slider */
@keyframes slideIn {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(26px);
  }
}

/* Optional: Optional styling for the "On" and "Off" labels */
.switchbtnlableh4 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .member-tabs {
    flex-direction: column;
    width: 100%;
  }

  .member-tabs .react-tabs__tab-list {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .welcomebox {
    background-size: cover;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 767px) {
  .popup-overlay {
    display: grid;
    place-items: start;
  }
}

@media (max-width: 480px) {
  .topbtn {
    width: 140px;
  }
}

.comment_userlist li .user_img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  flex-shrink: 0;
}

.comment_userlist li {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

.comment_userlist {
  height: fit-content;
  max-width: 80vh;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comment_userlist li .user_img img {
  border-radius: 50px;
  flex-shrink: 0;
}

.comment_userlist li h4 {
  font-size: 19px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.comment_userlist li h5 {
  font-size: 17px;
  font-weight: 400;
  color: #000000bb;
  margin: 0;
}

.comment_userlist li p {
  font-size: 15px;
  font-weight: 500;
  color: #000000bb;
  margin: 0;
  height: fit-content;
  max-height: 90px;
  overflow-y: auto;
  padding: 0px 5px;
}

p.scroll_bar .fa-thumbs-up {
  font-size: 45px;
}

.bEOvvC {
  cursor: pointer;
  height: 24px;
  max-width: 100%;
  user-select: none;
  padding-left: 8px;
  padding-right: 24px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent !important;
  appearance: none;
  direction: ltr;
  flex-shrink: 0;
}

.view_fileimgae {
  height: 300px !important;
  display: block;
}

.bOmZtP div:first-child {
  white-space: wrap !important;
}

.securitypage .modal-dialog {
  max-width: 80%;
  /* Set to 70% or your preferred width */
}

.sc-fAUdSK.sc-dntaoT.sc-dpBQxM.hHxwOk.hqEhgU.boKTiX.rdt_TableCol {
  min-width: 450px !important;
}

.cookies h5 {
  font-size: 16px;
  font-weight: 600;
  color: #121212bf;
}

.cookies p {
  font-size: 16px;
  font-weight: 600;
  color: #121212bf;
}

.sc-dstKZu.eAfwME.rdt_TableRow > div:last-child,
.sc-dstKZu.kQSTYI.rdt_TableRow > div:last-child {
  min-width: 450px;
}

.sc-dstKZu.eAfwME.rdt_TableRow > div:last-child,
.sc-dstKZu.kQSTYI.rdt_TableRow > div:last-child > div {
  flex-wrap: wrap;
}

a.d-flex.gap-3.align-items-center.listoficons.active {
  background: var(--buttonlight);
  padding: 5px;
  border-radius: 10px;
}

.all_search ul li {
  border-bottom: #fff;
}

.all_search ul li .people_profile {
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}

.all_search ul li h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.all_search ul li .add_friend {
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.all_search ul li .add_friend.cancel_btn {
  background-color: red;
  color: #fff;
}

.all_search .people_scroll {
  height: 60vh;
  overflow: auto;
  max-height: fit-content;
}

.all_search .group_scroll {
  height: 60vh;
  overflow: auto;
  max-height: fit-content;
}

video {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.memberdrop_cbox label {
  padding: 0px;
  margin-bottom: 0px;
  font-size: 16px !important;
}

button.add_confirm_btn.btn.btn-primary {
  background: #aaaaaa;
}

.nav_button {
  background-color: #121212;
  border-radius: 3px;
  color: #fff;
  font-size: 35px;
  border: none;
  width: fit-content;
  display: none;
  justify-content: center;
  align-items: center;
}

.leftbar {
  display: block;
}

@media (max-width: 990px) {
  .leftbar {
    display: none;
  }

  .nav_button {
    display: flex;
  }
}

.bg__dark {
  background-color: var(--lightblack);
}

.welcomebox {
  padding: 10% 0;
  background-color: transparent;
}

.login_main {
  background: var(--black) var(--background-image) no-repeat right top;
  background-size: cover;
}

.login_main header {
  background-color: transparent !important;
  box-shadow: none !important;
}

.login_main footer .ftblocks {
  background-color: transparent !important;
  box-shadow: none !important;
}

.noUi-touch-area {
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: none !important;
}

.noUi-handle:before,
.noUi-handle:after {
  background-color: transparent !important;
}

.noUi-connect {
  background: var(--white) !important;
}

.noUi-target {
  border: none !important;
  background-color: var(--orangelight) !important;
}
.noUi-tooltip {
  font-weight: 600;
}
.noUi-handle {
  border: 7px solid #6f75a4 !important;
}
.searchleftfilter {
  height: 100vh;
  overflow: auto;
}

@media (max-width: 786px) {
  .searchleftfilter {
    height: 100%;
  }
}
.gender_icovip {
  font-size: 20px;
}

/*******Editor*******/
.wrapper-class {
  border: 1px solid #ccc;
  padding: 10px;
}

.editor-class {
  min-height: 300px !important; /* Set a minimum height */
  height: 100%; /* Allow it to grow */
  padding: 10px;
  overflow-y: auto; /* Enable scrolling if content exceeds height */
}

.toolbar-class {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}

span.tox-statusbar__branding {
  display: none;
}
.tag_friends .text1line {
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 500;
  color: var(--buttonlight);
}
.text1line span a {
  color: var(--white);
}
.text1liness {
  color: var(--white);
}
.tag_friends span {
  font-size: 16px;
  font-weight: 500;
  color: var(--buttonlight);
}
.tag_friends .memperson {
  height: 50px;
  border-radius: 50%;
  aspect-ratio: 9/9;
  margin-left: -25px;
}
.tag_friends .imagetag-friend {
  margin-left: 25px;
}
.tag_friends svg {
  margin-top: 2px;
  font-size: 20px;
  flex-shrink: 0;
}
.tag_friends .memperson {
  background-color: var(--buttonlight);
  flex-shrink: 0;
}
.guest_invitepop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 636px;
  background-color: var(--lightblack);
  z-index: 98989;
  padding: 30px;
  border-radius: 20px;
  height: 90vh;
  overflow: auto;
}
.guest_invitepop .guestClose {
  position: absolute;
  top: 17px;
  right: 20px;
  font-size: 25px;
  border: none;
  background-color: transparent;
  color: rgba(255, 0, 0, 0.897);
}
@media (max-width: 1500px) {
  .guest_invitepop {
    width: 500px;
  }
}
@media (max-width: 786px) {
  .guest_invitepop {
    width: 90%;
  }
}
.guest_invitepop h4 {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}
.guest_invitepop .nav-item button {
  color: var(--buttonlight);
  font-size: 18px;
  font-weight: 500;
  border: none;
}
.guest_invitepop .nav-item button:hover {
  border: none;
}
.guest_invitepop .nav-item button.active {
  background-color: transparent !important;
  border: none;
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}
.guest_invitepop #controlled-tab-example {
  border: none;
}
.guest_invitepop .invite_people {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.guest_invitepop .invite_people h5 {
  font-size: 25px;
}
.guest_invitepop .invite_people .main_profile {
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
  flex-shrink: 0;
}
.guest_invitepop .invite_people .main_profile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.guest_invitepop .invite_people h6 {
  font-size: 18px;
  font-weight: 500;
}
.guest_invitepop .invite_people h5 {
  font-size: 18px;
  font-weight: 500;
}
.matualfriend .fimage {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.matualfriend .fimage img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.guest_invitepop .invite_people p {
  font-size: 17px;
  font-weight: 500;
  color: var(--buttonlight);
}
.send_messsage {
  padding: 7px 15px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--lightgray);
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
}
.send_messsage p {
  color: var(--white) !important;
}
.all-friend_btn button {
  padding: 5px 20px;
  background-color: var(--lightblack);
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  border: none;
  border-radius: 8px;
}
.all-friend_btn button.active {
  color: #fff;
  background-color: var(--primary);
}
.usergallery_pop .arrow {
  font-size: 30px;
  font-weight: 500;
  color: var(--white);
  position: absolute;
  z-index: 9898;
  border: none;
  background-color: transparent;
}
.usergallery_pop .arrow.left {
  top: 50%;
  left: -50px;
}
.usergallery_pop .arrow.right {
  top: 50%;
  right: -50px;
}

.search_content .sppeddate_time {
  background: var(--blackbtn);
  padding: 3px;
  position: relative;
  bottom: 0;
  left: 0;
}
.search_content {
  overflow: hidden;
}
.allspeed_text .speed-icon {
  position: absolute;
  top: -15px;
  left: -15px;
  color: #917d57;
}
@media (max-width: 786px) {
  .allspeed_text .speed-icon {
    top: 15px;
    left: 15px;
  }
}
.allspeed_text .speed-icon svg {
  font-size: 34px;
}

.allspeed_text {
  position: relative;
}
.allspeed_text .icon_rotate {
  transform: rotate(45deg);
}
.allspeed_text .icon_rotate svg {
  font-size: 32px;
}
.fourn_bottom {
  background-color: #917d57;
  padding: 5px 20px;
}
.fourn_bottom p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.lang_chnage button {
  border: none;
  background-color: transparent;
  color: rgb(168, 168, 168);
  font-size: 18px;
  font-weight: 600;
}
.lang_chnage button.active {
  border: none;
  background-color: transparent;
  color: #8c7651;
  font-size: 18px;
  font-weight: 600;
}
.lang_chnage h6 {
  border: none;
  background-color: transparent;
  color: #9a9a9a;
  font-size: 18px;
  font-weight: 600;
}
.topiconheart .heart_icon {
  background-color: transparent;
  border: none;
}
.topiconheart .heart_icon svg {
  font-size: 30px;
  color: #fff;
}
.topiconheart .heart_icon svg.active {
  color: #8c7651;
}
.not-found h1 {
  font-size: 30px;
  text-align: center;
  color: #000;
}
.not-found p {
  font-size: 20px;
  text-align: center;
  color: #000;
}
.error_image {
  width: 35%;
  margin: 0 auto;
}
.not-found .back_btn {
  background-color: var(--primary);
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 600;
  color: white;
  border-radius: 50px;
}

select.form-control {
  color: white !important;
}
.emchatbox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 85px;
  z-index: 9898;
}
@media (max-width: 756px) {
  .emchatbox aside {
    position: fixed;
    left: -50%;
    transform: translateX(-50%);
    width: 289px !important;
    top: -195px;
  }
}
.emoji-container button {
  background-color: transparent;
  border: none;
  font-size: 25px;
}
/* For Firefox */
.scroll_bar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 10px;
}
p {
  word-break: break-all;
}
/* Scrollbar track (background) */
.scroll_bar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray */
  border-radius: 10px;
}

/* Scrollbar handle (draggable part) */
.scroll_bar::-webkit-scrollbar-thumb {
  background: #888; /* Darker gray */
  border-radius: 10px;
}

/* On hover */
.scroll_bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* For Firefox */
.search_scroll::-webkit-scrollbar {
  width: 5px; /* Adjust width */
  height: 8px;
  border-radius: 10px; /* Adjust height for horizontal scrollbars */
}

/* Scrollbar track (background) */
.search_scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray */
  border-radius: 10px;
}

/* Scrollbar handle (draggable part) */
.search_scroll::-webkit-scrollbar-thumb {
  background: #888; /* Darker gray */
  border-radius: 10px;
}

/* On hover */
.search_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notinum {
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  background: #cd0909;
  color: #fff;
  aspect-ratio: 1;
  font-size: 12px;
  width: 20px;
  display: grid;
  place-items: center;
  border-radius: 100px;
}
button.closebtnpic {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #b0a18f;
  border: none;
  width: 30px;
  height: 30px;
  color: #fff;
  aspect-ratio: 1;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
}
.friend_formen h5 {
  font-size: 22px;
}

.editDeleteVisible {
  position: absolute;
  top: 0;
  right: 30px;
  padding: 10px;
}

.editDeleteVisible button {
  width: 25px;
  padding: 0;
  height: 25px;
}
.avimgs {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avimgs li {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow: hidden;
}
.avimgs li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* li:nth-child(1) { background: blue; }
li:nth-child(2) { background: green; }
li:nth-child(3) { background: purple; }
li:nth-child(4) { background: tomato; } */
.avimgs li:nth-child(n + 2) {
  margin-left: -50px;
}

.avimgs li:nth-child(n + 2) {
  margin-left: -10px;
}

.morebtnnotification button {
  font-size: 18px;
  color: var(--white);
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 8px;
  padding: 7px;
  display: block;
  width: 100%;
  background: transparent;
}

.morebtnnotification button:hover {
  background: var(--primary);
  color: var(--white);
}
.ratio-unset {
  aspect-ratio: unset !important ;
}
button.btn.backbtn {
  background: var(--primary);
  color: var(--white);
}

.avimgs1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avimgs1 li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow: hidden;
}
.avimgs1 li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avimgs1 li a {
  display: flex;
}
/* li:nth-child(1) { background: blue; }
li:nth-child(2) { background: green; }
li:nth-child(3) { background: purple; }
li:nth-child(4) { background: tomato; } */
.avimgs1 li:nth-child(n + 2) {
  margin-left: -50px;
}

.avimgs1 li:nth-child(n + 2) {
  margin-left: -10px;
}

.avimgsgroup {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avimgsgroup li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow: hidden;
}
.avimgsgroup li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avimgsgroup li a {
  display: flex;
  width: 100%;
  height: 100%;
}
/* li:nth-child(1) { background: blue; }
li:nth-child(2) { background: green; }
li:nth-child(3) { background: purple; }
li:nth-child(4) { background: tomato; } */
.avimgsgroup li:nth-child(n + 2) {
  margin-left: -50px;
}

.avimgsgroup li:nth-child(n + 2) {
  margin-left: -10px;
}
.newmessagearrow {
  background-color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  position: sticky;
  bottom: 10px;
  right: 10px;
}
.newmessagearrow svg {
  color: #fff;
  font-size: 20px;
}
.newmessagearrow span {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  font-size: 12px;
  border-radius: 50px;
  background-color: var(--primary);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_color .react-select__menu {
  background-color: red !important;
}
.readall_check {
  position: absolute;
  right: 10px;
  top: -35px;
}
.readall_check input {
  width: 20px;
  height: 20px;
  appearance: none; /* Removes default checkbox styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #ccc; /* Adds a border for visibility */
  border-radius: 5px; /* Ensures the rounded effect */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.readall_check input:checked {
  background-color: #007bff; /* Changes background when checked */
  border-color: #007bff;
}

.readall_check input:checked::after {
  content: "✔"; /* Custom checkmark */
  font-size: 14px;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.readall_check label {
  font-size: 18px;
  font-weight: 400;
  color: #7f8080;
}
.search_text .viewq_morre {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  text-decoration: underline;
  display: flex;
  justify-content: end;
  align-items: end;
  width: fit-content;
}
.CookieConsent {
  z-index: 9899 !important;
}
.darklinkcolor {
  font-size: 17px;
  font-weight: 500;
  color: var(--white);
}
.sidefixedbox::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.sidefixedbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.sidefixedbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.sidefixedbox::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.scroll_40::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scroll_40::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll_40::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scroll_40::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.mess_box_main::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.mess_box_main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.mess_box_main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.mess_box_main::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.shimg img {
  width: 100%;
  height: 100%;
}
.goupmediatext {
  color: var(--white);
}
.newclass {
  color: var(--white);
}
.breaktext {
  word-break: break-all;
}
.popup-overlay .close-button.close_mumber {
  top: -30px;
}
@media screen and (max-width: 767px) {
  .popup-overlay .close-button.close_mumber {
    top: 0px;
  }
}
