.searchmain .searchmenu .searchinput label {
  font-size: 30px;
  font-weight: 300;
  color: var(--white);
}
.searchmain .searchmenu .searchinput input {
  border-bottom: 1px solid #414040;
  background-color: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  color: var(--white);
}

.searchmain .searchmenu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.searchmain .searchmenu::-webkit-scrollbar-track {
  background: var(--black);
  border-radius: 10px;
}

.searchmain .searchmenu::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 10px;
}

.searchmain .searchmenu::-webkit-scrollbar-thumb:hover {
  background: #202020;
}
.searchmain .searchmenu {
  width: 100%;
  overflow: auto;
  top: 143px;
  position: sticky;
  height: 80vh;
}
@media (max-width: 768px) {
  .searchmain .searchmenu {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
.searchmain .searchmenu .seacrh_btns {
  background: var(--lightwhite) !important;
  border: none;
  background-color: #202020;
  box-shadow: none;
  border-radius: 10px;
  padding: 0px;
}
.searchmain .searchmenu .searchinput span {
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: #858585;
}
.searchmenu .accordion-button {
  background: var(--lightwhite) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.serachdrop h3 {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
}
.searchmenu .accordion-item {
  border: none !important;
  background-color: transparent;
}
.searchmain .accordion-button:not(.collapsed)::after {
  display: none;
}
.searchmain .accordion-button::after {
  display: none;
}
.serachdrop .searchicon {
  height: 60px;
  width: 60px;
  background-color: var(--lightblack);
  padding: 15px;
  border-radius: 50px;
}
.serachdrop .searchicon img {
  object-fit: contain;
}
.searchmenu button.accordion-button {
  padding: 0px !important;
  border-radius: 10px;
}
.searchmenu .accordion {
  border: none;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.searchmenu .memberdrop {
}
.searchmenu .accordion-body {
  padding: 0 !important;
}
.searchmenu .memberdrop.accordion {
  border: none;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 0px;
}
.searchmenu .memberdrop .accordion-button {
  background: var(--lightwhite) !important;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  padding: 8px 5px !important;
}

.searchmain .memberdrop .accordion-button::after {
  display: block !important;
  color: var(--white);
  mix-blend-mode: exclusion;
  filter: invert(1);
}
.searchmenu .memberdrop_listbox {
  background: var(--lightwhite) !important;
  padding: 9px 20px;
  border-radius: 10px;
}
.searchmenu .memberdrop_cbox {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.searchmenu .memberdrop_listbox .memberdrop_cbox label {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}

.searchmenu .memberdrop_listbox .memberdrop_cbox input {
  height: 16px;
  width: 16px;
}
.searchmenu .memberdrop_listbox .memberdrop_cbox input[type="range"] {
  height: 100% !important;
  width: 100% !important;
}
.searchmenu .memberdrop .accordion-button[aria-expanded="true"] {
  background: var(--lightwhite) !important;
  border: 1px solid #4b4f6a !important;
  border-radius: 10px !important;
}
.searchmenu .memberdrop .accordion-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.searchmain .search_content {
  border-radius: 15px;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.searchmain .search_content .vip_text {
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 98;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  padding: 0px 15px;
}
.searchmain .search_content .searchimg {
  height: 230px !important;
  position: relative;
  flex-shrink: 0;
}
.searchmain .search_content .searchimg img {
  border-radius: 10px 10px 0px 0px;
}
.searchmain .search_content .searchimg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000059;
  border-radius: 10px 10px 0px 0px;
}
.searchmain .search_content .searchwomen {
  height: 30px;
  width: 30px;
}
.searchmain .search_content .searchwomen img {
  object-fit: contain;
}
.searchmain .search_content .searchusere {
  position: relative;
  bottom: 0px;
  width: 100%;
  background: var(--lightblack);
  border-radius: 0px 0px 10px 10px;
  padding: 15px 0px;
  height: 100%;
}
.searchmain .search_content .searchusere h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
}
.searchmain .search_content .searchusere p.discription_p {
  height: 150px;
}
.searchmain .search_content .searchusere p {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: auto;
}
.searchmain .search_content .searchusere span {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  margin: 0;
}
.searchmain .search_content .searchmessage {
  height: 50px;
  width: 50px;
  background-color: var(--black) 000a1;
  border-radius: 50px;
  padding: 10px;
  border-color: transparent !important;
}
.searchmain .search_content .searchmessage:active {
  background-color: transparent !important;
}

.searchmain .search_content .searchgender_main {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  padding: 0px 15px;
}
.green_dot_vip {
  width: 13px;
  height: 13px;
  background-color: #00ab65;
  border-radius: 50%;
}

/*-------------model_chatbox-------------- */

.chat_box {
  background-color: var(--lightgray) !important;
  border-radius: 10px !important;
}
.checkbox.popup-overlay .close-button {
  right: 23px !important;
  top: 0px !important;
}
.checkbox .popup-content {
  width: 700px !important;
}
@media (max-width: 768px) {
  .checkbox .popup-content {
    width: 100% !important;
  }
}
.chat_box .mess_head {
  border-bottom: 1px solid var(--black);
  padding: 20px;
}
.chat_box .chat_profile {
  height: 70px;
  border-radius: 70px;
  overflow: hidden;
  width: 70px;
  flex-shrink: 0;
}
.chat_box .chat_profile img {
  -o-object-fit: cover;
  object-fit: cover;
}
.chat_box .chat_name h3 {
  font-size: 20px;
  font-weight: 400;
  color: var(--white);
  flex-shrink: 0;
}
.chat_box .chat_name h5 {
  font-size: 16px;
  font-weight: 400;
  color: var(--orangelight);
  flex-shrink: 0;
}
.chat_box .mess_box {
  padding: 20px;
}

.scroll_bar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #f1f1f1;
}

.scroll_bar::-webkit-scrollbar-track {
  background: var(--black);
}

.scroll_bar::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 6px;
}

.chat_box .mess_box .main_mess .mess_profile {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  overflow: hidden;
}
.chat_box .mess_box .main_mess .seen_profile {
  height: 15px;
  width: 15px;
  border-radius: 50px;
}
.chat_box .mess_box .main_mess .seen_profile img {
  border-radius: 50%;
}
.chat_box .mess_box .main_mess .mess_profile img {
  -o-object-fit: cover;
  object-fit: cover;
}
.right_mess .message {
  background-color: #6f75a4 !important;
  max-width: 250px;
  min-width: 200px;
}
.left_mess .message {
  background-color: #000 !important;
  color: #fff !important;
  max-width: 250px;
  min-width: 200px;
}
@media only screen and (max-width: 600px) {
  .right_mess .message {
    max-width: 200px;
    min-width: 200px;
  }
  .left_mess .message {
    max-width: 200px;
    min-width: 200px;
  }
}
.chat_box .mess_box .main_mess span {
  font-size: 12px;
  font-weight: 500;
  color: var(--orangelight);
}
.chat_box .mess_box .date_line {
  padding: 20px 0px;
  text-align: center;
}
.chat_box .mess_box .main_mess .message {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: var(--orangelight);
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  overflow-wrap: break-word;
}
.chat_box .mess_box .main_mess .send_image {
  height: fit-content;
  width: 200px;
  border-radius: 10px;
  background-color: transparent !important;
}
.chat_box .mess_box .main_mess .send_image img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.chat_box .right_mess {
  flex-direction: row-reverse;
}
.chat_box .message_type {
  padding: 20px;
  border-top: 1px solid var(--black);
  position: relative;
}
.chat_box .message_type .muti_main {
  width: 96% !important;
}
.chat_box .message_type .muti_main::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.chat_box .message_type .muti_main::-webkit-scrollbar-track {
  background: var(--black);
  border-radius: 10px;
}

.chat_box .message_type .muti_main::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 10px;
}
.chat_box .message_type .muti_images {
  width: 331px;
  height: auto;
  background-color: var(--lightblack);
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: -130px;
  left: 32px;
  overflow: auto;
}
@media (max-width: 600px) {
  .chat_box .message_type .muti_images {
    width: 100%;
    left: 0px;
  }
}
.chat_box .message_type .muti_images .delete_pop {
  top: 0px !important;
  right: 0px !important;
}
.chat_box .message_type .muti_images .delete_img {
  position: absolute;
  top: 3px;
  right: 6px;
  border: none;
  background-color: var(--white);
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  color: var(--black);
}
.chat_box .message_type .muti_images .delete_img svg {
  height: 25px !important;
  width: 25px !important;
  fill: red;
}
.chat_box .message_type .muti_image {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  flex-shrink: 0;
  position: relative;
}
.chat_box .message_type .muti_images .muti_image img {
  object-fit: cover;
  border-radius: 10px;
}

.chat_box .message_type .image_img {
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50px;
  overflow: hidden;
  background-color: var(--lightblack);
  border: none;
  flex-shrink: 0;
  display: block;
  position: relative;
}
.chat_box .message_type .image_img input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 988;
  opacity: 0;
  cursor: pointer;
  padding: 21px;
}
.chat_box .message_type input {
  width: 100%;
  background-color: var(--orangelight);
  border: none;
  border-radius: 5px;
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
}

.singlesearch .single_img {
  /* height: 400px !important; */
  height: 100%;
  border-radius: 20px;
}

.singlesearch .single_private {
  position: relative;
}
.singlesearch .single_private::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000008a;
  z-index: 98;
  border-radius: 20px;
}
.singlesearch .single_private p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  z-index: 989;
}
.singlesearch .single_img img {
  object-fit: cover;
  border-radius: 20px;
}
.singlesearch h2 {
  font-size: 25px;
  font-weight: 500;
  color: var(--white);
  line-height: normal;
  word-break: break-all;
}
.singlesearch p {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
  line-height: normal;
}
.singlesearch .notification_icon .noti_icon {
  width: 18px;
  height: auto;
}
.singlesearch .notification_icon .noti_icon img {
  object-fit: cover;
}
.singlesearch .notification_icon h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--pieach);
}
.singlesearch .single_btn button {
  border: none;
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white) !important;
  padding: 8px 30px;
}
.singlesearch .single_btn .report {
  border-color: #91183e !important;
  padding: 8px 40px;
}
.singlesearch .search_two {
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
}
.singlesearch .search_two img {
  object-fit: cover;
  border-radius: 20px;
}
.search_twoinfo {
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #363636b8;
  padding: 20px;
}
@media (max-width: 767px) {
  .search_twoinfo {
    height: 180px;
  }
}
.search_twoinfo .search_twoinfoicon {
  height: 50px;
  width: 50px;
}
@media (max-width: 767px) {
  .search_twoinfo .search_twoinfoicon {
    height: 30px;
    width: 30px;
  }
}
.search_twoinfo a {
  font-size: 16px;
  font-weight: 500;
  color: var(--white) fff8f;
  text-align: center;
}
@media (max-width: 768px) {
  .search_twoinfo a {
    font-size: 12px;
  }
}
.scroll_40 {
  max-height: 40vh;
  overflow: auto;
}
.search_twoinfo p {
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  text-align: center;
}
@media (max-width: 768px) {
  .search_twoinfo p {
    font-size: 12px;
  }
}
.recive_main .recive_two {
  height: 135px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 786px) {
  .recive_main .recive_two {
    height: 200px;
  }
}
.recive_main .recive_two img {
  object-fit: cover;
  border-radius: 20px;
}
.recive_main .recive_twoinfo {
  height: 135px;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #363636b8;
  padding: 20px;
}
.recive_main .recive_twoinfo .recive_twoinfoicon {
  height: 25px;
  width: 25px;
}
.recive_main .recive_twoinfo a {
  font-size: 14px;
  font-weight: 400;
  color: var(--white) fff8f;
  text-decoration: underline;
}
.recive_main .recive_twoinfo p {
  font-size: 14px;
  font-weight: 400;
  color: var(--white) fff8f;
}

.singlesearch .search_text h3 {
  font-size: 25px;
  font-weight: 600;
  color: var(--pieach);
}
.singlesearch .search_text textarea {
  background-color: transparent;
  border: 1px solid var(--orangelight);
  border-radius: 5px;
  color: var(--white);
  padding: 5px 10px;
}
.singlesearch .search_text .sendbtn {
  padding: 5px 20px;
  background-color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
  border: none;
}
.singlesearch .search_text {
  background-color: var(--lightgray);
  padding: 20px;
  border-radius: 10px;
}
.singlesearch .search_text .lookinp {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white) cf8a1;
}
.singlesearch .search_text .abt_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white) cf8a1;
}
.singlesearch .search_text .profile_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white);
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.singlesearch .search_text .friend_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white) !important;
}

.singlesearch .search_text .abt_icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50px;
  background-color: var(--lightblack);
  flex-shrink: 0;
}
.singlesearch .search_text .event_ul {
  padding: 0;
  height: 72px;
  overflow: auto;
}
.singlesearch .search_text .event_ul li .group_profile {
  height: 50px;
  width: 50px;
  background-color: var(--pieach);
  border-radius: 50px;
  overflow: hidden;
}
.singlesearch .search_text .profile_ul {
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.singlesearch .search_text .profile_ul li .group_profile {
  height: 50px;
  width: 50px;
  background-color: var(--pieach);
  border-radius: 50px;
  overflow: hidden;
}
.singlesearch .search_text .profile_ul li .group_profile img {
  object-fit: cover;
}
.singlesearch .search_text .profile_ul li h6 {
  font-size: 18px;
  font-weight: 400;
  color: var(--white) cf8a1;
}
.singlesearch .search_text .profile_ul li {
  position: relative;
  padding-bottom: 20px;
}
.singlesearch .search_text .profile_ul li:last-child {
  padding: 0;
}
.singlesearch .search_text .profile_ul li:last-child:after {
  content: "";
  height: 0px;
  background-color: transparent;
}
.singlesearch .search_text .profile_ul li::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 126%;
  height: 3px;
  background-color: var(--lightwhite);
  left: -29px;
}
.singlesearch .search_text .profile_ul li p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white) cf8a1;
}
.singlesearch .search_text .profile_ul li p span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white) cf8a1;
}
.singlesearch .search_text .view_more {
  font-size: 14px;
  font-weight: 500;
  color: var(--white) fff8f;
  text-decoration: underline;
  text-align: end;
}
.friends_profile {
  height: 130px;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
}
.friends_profile img {
  object-fit: cover;
}
.singlesearch .search_text .search_scroll {
  max-height: 374px;
  overflow: auto;
}
@media (min-width: 786px) {
  .singlesearch .search_text .search_scroll {
    height: 374px;
    overflow: auto;
  }
}
.gallery_pop {
  background: var(--black) !important;
  border-radius: 20px;
  padding: 20px;
}
.gallery_pop h3 {
  color: var(--white);
  font-size: 20px;
  text-transform: capitalize;
}
.gallery_pop input[type="text"],
.gallery_pop input[type="time"],
input[type="date"],
input[type="password"],
input[type="file"],
.gallery_pop textarea {
  background: var(--lightwhite) !important;
  color: var(--white);
  font-size: 16px;
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 10px 15px;
}
.gallery_pop .listbox .cbox {
  color: var(--white);
  font-size: 14px;
}
.gallery_pop button {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--white);
  text-align: center;
  border: none;
  border-radius: 8px;
  padding: 6px;
  display: block;
  width: 100%;
  background: var(--primary);
}
.gallery_pop a:hover,
.gallery_pop button:hover {
  background: var(--primary);
  color: var(--white);
}
.gallery_pop .datetimeline input {
  padding-top: 20px;
}
.bg-transprent {
  background-color: transparent !important;
}
.recive_main .revice_type li h6 {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
}
.recive_main .revice_type li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.recive_main .revice_type li p {
  font-size: 16px;
  font-weight: 400;
  color: #747474c9;
  word-break: break-all;
}
.recive_main {
  background-color: var(--lightblack);
  padding: 20px;
  border-radius: 10px;
}

.singlesearch .single_btn button {
  border: none;
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #606060;
  padding: 8px 30px;
}
.recive_main_btn .report {
  border-color: #91183e !important;
  padding: 8px 40px;
  background-color: transparent;
  border: none;
  border: 1px solid var(--white);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
}
.upload_prev {
  position: absolute;
  border-radius: 13px;
  /* opacity: 0; */
}
.invite_pop {
  overflow: hidden;
}

.invite_pop .invite_text {
  font-size: 15px;
  font-weight: 400;
  color: var(--white);
}
.invite_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 50vh;
  overflow: auto;
}
.invite_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #cabca530;
}

.invite_list li .invite_profile {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
}
.invite_list li h4 {
  font-size: 20px;
  font-weight: 400;
  color: var(--white);
}
button.opa {
  opacity: 0.5;
  pointer-events: none;
}
.delete_pop .delete_btn {
  padding: 8px 25px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
}
.delete_pop .yes-btn {
  background-color: #8a7550 !important;
}
.delete_pop .no-btn {
  background-color: #6e75a8 !important;
}
.error_meess {
  background-color: var(--lightblack);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 14px 0px red;
}
.error_notification p {
  font-size: 18px;
  font-weight: 500;
  color: red;
}
.error_alert .close-button {
  right: 20px !important;
  top: 0px !important;
}

.sucess_meess {
  background-color: var(--lightblack);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 14px 0px green;
}
.sucess_notification p {
  font-size: 18px;
  font-weight: 500;
  color: green;
}
.danger_notification p {
  font-size: 18px;
  font-weight: 500;
  color: red;
}
.danger_meess {
  background-color: var(--lightblack);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 14px 0px red;
}
.sucess_alert .close-button {
  right: 20px !important;
  top: 2px !important;
}
.interested_btn {
  background-color: green !important;
  position: relative;
  padding: 8px 40px !important;
}
.interested_btn:hover {
  color: var(--white) !important;
}
.interested_btn::after {
  content: "✔";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

@media (max-width: 786px) {
  .usergallery_pop .gallery_im1g {
    height: auto !important;
  }
  .usergallery_pop .arrow.right {
    top: 25% !important;
    right: 20px !important;
  }
  .usergallery_pop .arrow.left {
    top: 25% !important;
    left: 20px !important ;
  }
}

.usergallery_pop .gallery_im1g {
  height: 82vh;
  border-right: 2px solid #cabca530;
}
.usergallery_pop .gallery_im1g img {
  object-fit: contain;
}
.usergallery_pop {
  background: var(--lightgray);
  border-radius: 20px;
  overflow: hidden;
}
.usergallery_pop h3 {
  color: var(--white);
  font-size: 28px;
  font-weight: 500;
}
.usergallery_pop h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24.38px;
  color: var(--white);
}

.usergallery_pop a {
  font-size: 17px;
  font-weight: 500;
  line-height: 24.38px;
  color: var(--white);
}
.usergallery_pop p {
  font-size: 15px;
  color: var(--white);
  font-weight: 400;
  word-break: break-all;
}
.usergallery_pop .like_icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.usergallery_pop .likecount {
  font-size: 16px;
  color: var(--white);
  font-weight: 400;
}
.usergallery_pop .like_icon img {
  object-fit: contain;
}
.usergallery_pop .bordr-bt {
  border-bottom: 2px solid #cabca530;
  padding-bottom: 15px;
}
.usergallery_msg .profile_img {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;
}
.usergallery_msg {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  max-height: 55vh;
  overflow: auto;
}
@media (max-height: 1300px) {
  .usergallery_msg {
    max-height: 70vh;
  }
}
@media (max-height: 961px) {
  .usergallery_msg {
    max-height: 65vh;
  }
}
@media (max-height: 800px) {
  .usergallery_msg {
    max-height: 55vh;
  }
}

@media (max-width: 786px) {
  .usergallery_msg {
    max-height: 200px;
  }
}
.usergallery_from {
  padding: 0px 20px 30px 20px;
}
.usergallery_from .comment_input input {
  width: 100%;
  background-color: var(--black);
  border: none;
  background: var(--lightblack);
  padding: 10px;
  border-radius: 25px;
}
.usergallery_from .profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;
}
.usergallery_from .profile_img img {
  object-fit: cover;
  flex-shrink: 0;
}
.usergallery_msg .profile_img img {
  object-fit: cover;
  flex-shrink: 0;
}
.usergallery_msg li {
  display: flex;
  align-items: start;
}
.usergallery_msg .comment_text {
  background: #3c3c3c;
  border-radius: 15px;
  padding: 10px;
  min-width: 50%;
  max-width: 70%;
}
.usergallery_msg .comment_text h6 {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.94px;
}
.usergallery_msg .comment_text p {
  color: #d9d9d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
}
.friend_formen {
  background: var(--lightgray);
  overflow: hidden;
  border-radius: 10px;
  display: block;
}
.friend_formen .confirm_btn {
  background-color: green;
  color: var(--white);
}
.friend_formen .add_confirm_btn {
  background-color: #0d6efd;
  color: var(--white);
}
.friend_formen .cancel_request_btn {
  background-color: #bb2d3b;
  color: var(--white);
}
.friend_formen .cancel_btn {
  background-color: red;
  color: var(--white);
}
.friend_formen .fromimg {
  width: 80px;
  height: auto;
}
.friend_formen h3 {
  color: var(--white);
  font-size: 20px;
}
.friend_formen h4 {
  color: var(--white);
  font-size: 14px;
}
.friend_formen p {
  color: var(--white);
  font-size: 14px;
}
.friend_formen button {
  border: none;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #606060;
  padding: 8px 30px;
}
.inboxblock .request_head h5 {
  font-size: 30px;
  font-weight: 600;
  color: var(--white);
}

.all_formen {
  background: var(--lightgray);
  overflow: hidden;
  border-radius: 10px;
  display: block;
}

.all_formen .fromimg {
  width: 120px;
  height: 100%;
}
.all_formen h3 {
  color: var(--white);
  font-size: 20px;
}
.all_formen h4 {
  color: var(--white);
  font-size: 14px;
}

.comment_input {
  border-top: 1px solid #000000;
  padding-top: 16px;
}

.comment_input .user_profile {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50px;
  overflow: hidden;
}
.comment_input .user_profile img {
  object-fit: cover;
}
.comment_input h5 {
  font-size: 15px;
  font-weight: 600;
  color: var(--white);
}

.comment_input .comment_user {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}
.comment_input .comment_time {
  font-size: 13px;
  font-weight: 500;
  color: var(--white) fff9d;
  display: block;
}

.comment_input .comment_user {
  max-height: 70px;
  overflow-y: auto;
}
.comment_input .comment_section {
  max-height: 150px;
  overflow-y: auto;
  word-break: break-all;
}
.comment_input input[type="text"] {
  width: 100%;
  padding: 8px 10px;
  border: none;
  background-color: #3c3c3c;
  border-radius: 8px;
  color: #fff;
}
.comment_input .post_btn {
  flex-shrink: 0;
  padding: 5px 20px;
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #adabab;
}
.darkmode_toggle svg {
  width: 20px;
}

.darkmode_toggle {
  position: fixed;
  bottom: 38px;
  right: 20px;
  border: none;
  background: var(--orangelight);
  padding: 10px;
  z-index: 99899;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .darkmode_toggle {
    top: 8px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 768px) {
}

.comment_postbtn {
  padding: 9px 25px;
  border: none;
  background-color: var(--primary);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
}

/*******Pawan****/
.spinner-border.text-primary.postloader {
  width: 20px;
  height: 17px;
}
.liked {
  background: transparent;
  border: none;
  color: red;
}
.send_load {
  position: absolute;
  right: 28px;
}
.date-load {
  position: absolute;
  left: 49%;
}

.requestscroll {
  height: 400px;
  overflow-y: auto;
}
.comment-scroll {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

li {
  list-style: none;
}
.couple_inner .checkmark {
  height: 20px !important;
  width: 20px !important;
}
.reg_form_spin {
  position: absolute;
  left: 76%;
  transform: translate(-50%, -50%);
  top: 50%;
}

/* <---------------------admin-panel------------------> */

.admin .admin_bar {
  width: 300px;
  position: sticky;
  top: 0;
  left: 0;
  background: url(/src/assets/images/pattern_h.png) no-repeat, center;
  padding: 30px;
  height: 100vh;
  background-color: #3b3b3b;
  flex-shrink: 0;
  transition: 0.3s all ease-in-out;
}
@media (max-width: 1000px) {
  .admin .admin_bar {
    opacity: 0;
    position: fixed;
    top: 0;
    width: 0;
    z-index: 0;
  }
}

.admin .admin_bar .admin_profile {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
}
.admin .admin_bar .admin_profile img {
  object-fit: cover;
}
.admin-top h5 {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
}
.admin-top {
  border-bottom: 2px solid var(--white);
  padding-bottom: 20px;
}
.admin-top h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}
.admin_nav a {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  padding: 10px;
  border-radius: 5px;
}
.admin_nav button {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  padding: 10px;
  border-radius: 5px;
  text-align: start;
  text-decoration: none;
  width: 100%;
}
.admin_nav .dropdown-menu {
  position: relative !important;
  transform: none !important;
}
.admin_nav button:focus,
.admin_nav button:hover {
  color: var(--white);
}
.admin_nav button:active {
  background-color: var(--lightwhite);
  color: var(--white) !important;
}
.admin_nav button:hover {
  background-color: var(--lightwhite);
  color: var(--white);
}
.admin_nav a:focus,
.admin_nav a:hover {
  color: var(--white);
}
.admin_nav a:active {
  background-color: var(--lightwhite);
}
.admin_nav a:hover {
  background-color: var(--lightwhite);
  color: var(--white);
}
.admin_nav .active {
  background-color: var(--lightwhite);
  color: var(--white);
}

.admin .admin_bar .dropdown-menu.show {
  position: relative;
  inset: none;
  transform: none;
  background: transparent;
  border: none;
  width: 100%;
}
.admin .admin_bar .dropdown-menu.show a:hover {
  background-color: var(--lightwhite);
  color: var(--white);
}
.admin .admin_bar .dropdown-menu.show a {
  padding: 10px;
  border-radius: 5px;
}
.admin .main_dashboard {
  padding: 35px;
  width: 100%;
}
.admin .main_dashboard .hudJpJ,
.dknajr,
.htmdXE,
.rdt_TableHead,
.rdt_TableHeadRow {
  background-color: #3b3b3b !important;
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
}
.admin .main_dashboard .kQSTYI:not(:last-of-type) {
  border-bottom-color: rgba(0, 0, 0);
}
.admin .main_dashboard .search_filter {
  padding: 5px 10px;
  width: 250px;
  background-color: var(--white);
  border: none;
  color: var(--black);
  font-size: 16px;
}
.admin .main_dashboard .dataedit_btn {
  padding: 5px 10px;
  background-color: #4caf50;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  border: none;
}

.admin .main_dashboard .refund_btn {
  padding: 5px 10px;
  background-color: #4caf50;
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  border: none;
}
.admin .main_dashboard .datadelete_btn {
  padding: 5px 10px;
  background-color: red;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  border: none;
}
.admin .main_dashboard .deactivate_btn {
  padding: 5px 10px;
  background-color: red;
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  border: none;
}

.admin .main_dashboard [data-column-id="5"] {
  min-width: 250px;
}
.admin .main_dashboard [data-column-id="5"] {
  min-width: 250px;
}
.admin .main_dashboard .pay_table [data-column-id="5"] {
  min-width: 100px !important;
}
.admin .main_dashboard .pay_refund [data-column-id="6"] {
  min-width: 200px !important;
}
.admin .main_dashboard .pay_refund [data-column-id="5"] {
  min-width: 100px !important;
}
.admin .main_dashboard .bpcekY,
.bEOvvC option {
  color: #fff;
  text-align: center;
  background-color: #fff !important;
}
.admin .main_dashboard .bEOvvC {
  padding: 10px;
}
.admin .main_dashboard .bpcekY svg {
  right: auto;
}
.edit_admin .modal-header {
  background-color: #3b3b3b;
}
.edit_admin .modal-header button.btn-close {
  filter: invert(1);
}
.edit_admin .modal-footer button.btn.btn-primary {
  border-color: transparent;
}
.edit_admin .form-label {
  color: #000;
}

.membershipstatus {
  background-color: var(--white) !important;
}

.nav_openres {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 988;
  display: none;
}
.nav_openres button {
  border: none;
  font-size: 25px;
  color: var(--white);
  background-color: transparent;
}
@media (max-width: 1000px) {
  .nav_openres {
    display: block;
  }
}
.nav_closeres {
  top: 10px;
  position: absolute;
  z-index: 998;
}
.nav_closeres button {
  border: none;
  font-size: 25px;
  color: var(--white);
  background-color: transparent;
}

/* <------------------------admin--Close------------------------> */
.accordion-button::after {
  content: "\f078"; /* Unicode for FontAwesome down arrow */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.memberdrop_cbox {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.memberdrop_cbox ul {
  padding: 0;
}
.custom-data-table {
  width: 71vw !important;
}

button#dropdown-custom-components.active {
  background-color: var(--lightwhite);
  color: white;
}
a.active.submenuadmin.dropdown-item {
  background-color: var(--lightwhite);
  color: white;
}

.payment-form {
  position: relative;
}
.payment-form form {
  padding: 50px;
  background-color: #212121;
  border-radius: 10px;
}
.payment-form form label {
  font-size: 17px;
  font-weight: 300;
  color: #fff;
}
.payment-form form input {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 7px 10px;
  color: #fff;
  border-radius: 5px;
}
.payment-form h4 {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.payment-form .submit_btn {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.card-input input {
  border: 1px solid #fff !important;
  background-color: transparent !important;
  padding: 7px 10px !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.card-input {
  border: 1px solid #fff !important;
  background-color: transparent !important;
  padding: 7px 10px !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.noitifiaction-count {
  position: absolute;
  background: orange;
  height: 25px;
  width: 25px;
  padding: 4px;
  border-radius: 50%;
  font-size: 14px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  top: -9px;
  right: -5px;
}
select#status {
  color: black;
  background: white !important;
}

.noUi-horizontal .noUi-tooltip {
  bottom: unset !important;
  top: -44px !important;
}

.noUi-horizontal .noUi-handle {
  width: 20px !important;
  height: 20px !important;
  right: -13px !important;
  top: -6px !important;
  border-radius: 100px !important;
}
.noUi-horizontal {
  height: 8px !important;
}
.noUi-connect {
  background: var(--orangelight) !important;
}
.breakline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.editDeleteVisible {
  background: #ccc;
  padding: 3px;
  border-radius: 12px;
}
.formall {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.custom-modal-width .modal-dialog {
  max-width: 80%; /* Adjust as needed, e.g., 60%, 90%, etc. */
}
.allspeed_text a h4 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white);
}
.allspeed_text a {
  background-color: var(--lightgray);
  padding: 45px 20px;
}
.allspeed_text a p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  padding: 5px 0px;
}
.search-box {
  position: relative;
  width: 210px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  transition: 0.3s ease-in-out;
  border: 1px solid var(--white);
}

.search-box:hover,
.search-box:focus-within {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  border: 1px solid var(--white);
}

.search-box input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  color: var(--white);
  font-size: 16px;
  width: 100%;
  padding-left: 10px;
}

.search-box input::placeholder {
  color: var(--white);
}

.search-box i {
  font-size: 20px;
  color: var(--white);
}
.main_mess .remove_mess {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 20px;
  color: var(--white);
  line-height: 0;
}
.popup-menu {
  position: absolute;
  top: 0;
  right: 15px;
  width: 87px;
  background: var(--blurback);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 0px 0;
  list-style: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  overflow: hidden;
}

.popup-menu li {
  padding: 4px 0;
  color: var(--white);
  cursor: pointer;
  transition: all 0.2s;
}

.popup-menu li:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.news_main .latest h1 {
  font-size: 30px;
  font-weight: 600;
  color: var(--white);
}
.news_main .latest .main_sub {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  /* background-color: var(--primary); */
  text-transform: uppercase;
}
.news_main .latest .main_sub .view_all {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  border: none;
  background-color: #917a53 !important;
  padding: 2px 20px;
}
.news_main .latest .main_sub .view_all:hover {
  background-color: var(--primary) !important;
}
.news_main .latest h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  /* background-color: var(--primary); */
  padding: 4px 10px;
  text-transform: uppercase;
}
.news_main .latest .news_back {
  background-color: var(--lightgray);
  padding: 20px;
}
.news_main .latest .news_back .postblock {
  background-color: var(--lightwhite);
}
.news_main .latest .news_right_event .profile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #917a53 !important;
  flex-shrink: 0;
}
.news_main .latest .news_right_event h5 {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  word-break: break-all;
}
.news_main .latest .news_right_event p {
  font-size: 13px;
  font-weight: 500;
  color: var(--orangelight);
}
.news_main .latest .news_back p a {
  font-size: 13px;
  font-weight: 700;
  color: var(--black);
}
.news_main .latest .news_right_event p span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.news_main .latest .news_right_event {
  max-height: 350px;
  overflow: auto;
}
select.select_color {
  color: var(--textcolor) !important;
  background: var(--lightblack);
}
.css-1nmdiq5-menu > * {
  color: var(--textcolor) !important;
  background: var(--lightblack);
}
.css-1nmdiq5-menu > *:hover {
  background: var(--lightblack);
  color: var(--textcolor) !important;
}
.message_s-btn button {
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 20px;
}
.message_s-btn .deltebtn {
  background-color: red;
  color: #fff;
  border-radius: 10px;
}
.message_s-btn .select_btn {
  border-radius: 10px;
  background-color: var(--black);
  color: var(--white);
}
.message_s-btn .select_btn.active {
  color: #fff;
  background-color: var(--primary);
}
.font-bold {
  font-weight: 500 !important;
  color: var(--white);
}
h1 {
  color: var(--white) !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.search_twoinfo strong {
  color: #a07c42;
}

.spinner_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7); /* Optional: Adds a light overlay */
  z-index: 9999;
}
.popup-menu.align-items-end {
  font-size: 12px;
}
